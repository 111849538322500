.btn {
    margin: 10px;
    padding: 10px;
    font-size: 16px;
    border-radius: 0.625rem;
  }
  
  .btn-primary {
    background-color: #000;
    color: white;
    border-color: white;
  }