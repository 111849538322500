/* Timeline Styles */
.timeline-wrapper {
    position: relative;
    overflow: clip;
    padding: 20px 0;
    margin: 0 auto;
    max-width: 100%;
  }
  
  .timeline-container {
    display: flex;
    overflow-x: auto;
    padding: 20px 40px;
    scroll-behavior: smooth;
    width: calc(100% + 80px);
  }
  
  .timeline-line, .timeline-dot {
    background: #007bff;
  }
  
  .timeline-line {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    height: 4px;
    z-index: 1;
  }
  
  .timeline-entry {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 40px;
    position: relative;
    z-index: 2;
    width: 25%;
    min-width: 300px;
    animation: fadeInUp 0.5s ease-in-out forwards;
    opacity: 0;
  }
  
  .timeline-year {
    color: black;
    font-size: 16px;
    position: absolute;
    bottom: 100%;
    white-space: nowrap;
  }
  
  .timeline-icon {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    margin-top: 10px;
    position: relative;
    z-index: 3;
  }
  
  .timeline-info {
    background: white;
    padding: 10px;
    border-radius: 5px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    margin-top: 20px;
    text-align: center;
    z-index: 3;
    position: relative;
  }
  
  /* Hover Effects */
  .timeline-icon:hover .timeline-dot {
    transform: scale(1.2);
    transition: transform 0.3s ease-in-out;
  }
  
  .timeline-info.hovered {
    transform: translateY(-10px);
    transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  }
  
  .timeline-dot {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    transition: background 0.3s, transform 0.3s;
  }
  
  /* Animations */
  @keyframes fadeInUp {
    from {
      opacity: 0;
      transform: translateY(20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  /* nth-child rules for animation delay */
  .timeline-entry:nth-child(1) { --index: 1; }
  .timeline-entry:nth-child(2) { --index: 2; }
  /* Add as many nth-child rules as you have entries */
  
  /* Fade Effect */
  .timeline-fade-left, .timeline-fade-right {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100px;
    pointer-events: none;
    background: linear-gradient(to left, transparent, white 50%);
    z-index: 10;
  }
  
  .timeline-fade-right {
    right: 0;
    transform: rotateZ(180deg);
  }
  
  /* Scrollbar Styling */
  .timeline-container::-webkit-scrollbar {
    display: none;
  }
  
  .timeline-container {
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
  
  
  @media (max-width: 768px) {  
    .timeline-container {
      padding: 20px; /* Reduce padding on mobile */
    }
  
    .timeline-entry {
      width: 100%; /* Full width timeline entries on mobile */
      min-width: 100; /* Override min-width on mobile */
    }
  
    .timeline-fade-left, .timeline-fade-right {
      position: absolute;
      top: 0;
      bottom: 0;
      width: 10px;
      pointer-events: none;
      background: linear-gradient(to left, transparent, white 50%);
      z-index: 10;
    }
  }