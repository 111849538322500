/* Google Fonts Import */
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap');

/* Base Styles */
body, html {
  margin: 0;
  padding: 0;
  font-family: 'Roboto', sans-serif;
  scroll-behavior: smooth;
}

/* Content Styling */
.content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start; /* Align items to the start on the cross axis */
  background: linear-gradient(to top right, #263A49, #1E2E3A, #151F28, #162029);
  color: white;
  padding: 2rem;
  min-height: 54vh;
}

.content .left, .content .right {
  flex: 1;
}

.content .left {
  margin-bottom: 2rem;
}


.nameHeader, .welcome {
  margin: 0 0 1rem 0;
  font-weight: 500;
}

.nameHeader {
  color: #FFF;
  font-size: 3.3rem;
}

.linkedin-badge {
  position: relative;
  bottom: 0;
  left: 0;
}

span.blockspam {
  display: none;
}

.welcome {
  color: #A0A0A0;
  font-size: 1.5rem;
}

.button {
  text-transform: capitalize;
  cursor: pointer;
  border-radius: 0.625rem;
  border: 1px solid #FFF;
  background: #000;
  color: #ebebeb;
  font-size: 1rem;
  font-weight: 700;
  padding: 0.5rem 1rem;
  margin-top: 1rem;
}

/* Bottom Half Section Styling */
.bottom-half {
  background: white;
  min-height: 50vh;
}

/* Responsive Styles */
@media (min-width: 768px) {
  .content {
    flex-direction: row;
    align-items: center; /* Ensure both left and right content are vertically centered */
  }

  .content .left, .content .right {
    max-width: 45%;
    margin-bottom: 0; /* Reset the bottom margin for left content */
  }

  .content .right {
    justify-content: flex-end;
    margin-top: 0; /* Reset the top margin for right content */
  }
}

@media (min-width: 1024px) {
  .nameHeader {
    font-size: 3.6rem;
  }

  .welcome {
    font-size: 1.9rem;
  }

  .button {
    font-size: 1.125rem;
    padding: 0.6rem 1.5rem;
  }
}

@media (max-width: 768px) {
  .content {
    flex-direction: column;
    align-items: stretch; /* Stretch items to take full width on mobile */
    justify-content: center;
  }

  .content .left, .content .right{
    margin-bottom: 60%; /* Adjust bottom margin for mobile */
  }

  .content .left, .content .right {
    flex: none; /* Do not allow flex items to grow or shrink */
    max-width: 100%; /* Full width on mobile */
    
  }
}


.email-address {
  color: #FFF; /* Add color styling to the email */
}

/* Style for the "Get in Touch" button */
#getInTouchButton {
  text-transform: capitalize;
  cursor: pointer;
  border-radius: 0.625rem;
  border: 1px solid #FFF;
  background: #000;
  color: #ebebeb;
  font-size: 1rem;
  font-weight: 700;
  padding: 0.5rem 1rem;
  margin-top: 1rem;
}
