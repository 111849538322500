.card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  background-color: white;
  width: 15rem;
  height: 27rem;
  padding: 1rem;
  border-radius: 15px;
}

.tags {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 1rem;
}

.tag {
  background-color: #f0f0f0;
  border-radius: 25px;
  padding: .5em .75em;
  font-size: .75em;
  color: #333;
  margin: .25em;
}

.card img {
  margin-top: 0rem;
  width: 14rem;
  border-radius: 15px;
}

.card h2 {
  margin: 0;
  margin-top: .5rem;
  color: black;
}

.card p {
  margin: .5rem 0;
  color: black;
}

.readMore {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 0.125rem;
}

.minutesRead {
  color: black;
}


.btn {
  background-color: #0094ff;
  color: #fff;
  border: none;
  outline: none;
  font-size: 1rem;
  border-radius: 20px;
  padding: 5px 0.5rem;
  /* margin: 0 0.5rem ; */
  width: fit-content;
}


@media (max-width: 768px) {
  .card {
    align-items: flex-start;
    width: 10rem;
    height: 20rem;
    padding: 0.5rem;
    border-radius: 15px;
    align-items: center;
  }
  .card img {
    width: 9rem;
    border-radius: 15px;
    align-items: center;
  }
}