@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap);
/* Google Fonts Import */

/* Base Styles */
body, html {
  margin: 0;
  padding: 0;
  font-family: 'Roboto', sans-serif;
  scroll-behavior: smooth;
}

/* Content Styling */
.content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start; /* Align items to the start on the cross axis */
  background: linear-gradient(to top right, #263A49, #1E2E3A, #151F28, #162029);
  color: white;
  padding: 2rem;
  min-height: 54vh;
}

.content .left, .content .right {
  flex: 1 1;
}

.content .left {
  margin-bottom: 2rem;
}


.nameHeader, .welcome {
  margin: 0 0 1rem 0;
  font-weight: 500;
}

.nameHeader {
  color: #FFF;
  font-size: 3.3rem;
}

.linkedin-badge {
  position: relative;
  bottom: 0;
  left: 0;
}

span.blockspam {
  display: none;
}

.welcome {
  color: #A0A0A0;
  font-size: 1.5rem;
}

.button {
  text-transform: capitalize;
  cursor: pointer;
  border-radius: 0.625rem;
  border: 1px solid #FFF;
  background: #000;
  color: #ebebeb;
  font-size: 1rem;
  font-weight: 700;
  padding: 0.5rem 1rem;
  margin-top: 1rem;
}

/* Bottom Half Section Styling */
.bottom-half {
  background: white;
  min-height: 50vh;
}

/* Responsive Styles */
@media (min-width: 768px) {
  .content {
    flex-direction: row;
    align-items: center; /* Ensure both left and right content are vertically centered */
  }

  .content .left, .content .right {
    max-width: 45%;
    margin-bottom: 0; /* Reset the bottom margin for left content */
  }

  .content .right {
    justify-content: flex-end;
    margin-top: 0; /* Reset the top margin for right content */
  }
}

@media (min-width: 1024px) {
  .nameHeader {
    font-size: 3.6rem;
  }

  .welcome {
    font-size: 1.9rem;
  }

  .button {
    font-size: 1.125rem;
    padding: 0.6rem 1.5rem;
  }
}

@media (max-width: 768px) {
  .content {
    flex-direction: column;
    align-items: stretch; /* Stretch items to take full width on mobile */
    justify-content: center;
  }

  .content .left, .content .right{
    margin-bottom: 60%; /* Adjust bottom margin for mobile */
  }

  .content .left, .content .right {
    flex: none; /* Do not allow flex items to grow or shrink */
    max-width: 100%; /* Full width on mobile */
    
  }
}


.email-address {
  color: #FFF; /* Add color styling to the email */
}

/* Style for the "Get in Touch" button */
#getInTouchButton {
  text-transform: capitalize;
  cursor: pointer;
  border-radius: 0.625rem;
  border: 1px solid #FFF;
  background: #000;
  color: #ebebeb;
  font-size: 1rem;
  font-weight: 700;
  padding: 0.5rem 1rem;
  margin-top: 1rem;
}

/* Timeline Styles */
.timeline-wrapper {
    position: relative;
    overflow: clip;
    padding: 20px 0;
    margin: 0 auto;
    max-width: 100%;
  }
  
  .timeline-container {
    display: flex;
    overflow-x: auto;
    padding: 20px 40px;
    scroll-behavior: smooth;
    width: calc(100% + 80px);
  }
  
  .timeline-line, .timeline-dot {
    background: #007bff;
  }
  
  .timeline-line {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    height: 4px;
    z-index: 1;
  }
  
  .timeline-entry {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 40px;
    position: relative;
    z-index: 2;
    width: 25%;
    min-width: 300px;
    animation: fadeInUp 0.5s ease-in-out forwards;
    opacity: 0;
  }
  
  .timeline-year {
    color: black;
    font-size: 16px;
    position: absolute;
    bottom: 100%;
    white-space: nowrap;
  }
  
  .timeline-icon {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    margin-top: 10px;
    position: relative;
    z-index: 3;
  }
  
  .timeline-info {
    background: white;
    padding: 10px;
    border-radius: 5px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    margin-top: 20px;
    text-align: center;
    z-index: 3;
    position: relative;
  }
  
  /* Hover Effects */
  .timeline-icon:hover .timeline-dot {
    transform: scale(1.2);
    transition: transform 0.3s ease-in-out;
  }
  
  .timeline-info.hovered {
    transform: translateY(-10px);
    transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  }
  
  .timeline-dot {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    transition: background 0.3s, transform 0.3s;
  }
  
  /* Animations */
  @keyframes fadeInUp {
    from {
      opacity: 0;
      transform: translateY(20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  /* nth-child rules for animation delay */
  .timeline-entry:nth-child(1) { --index: 1; }
  .timeline-entry:nth-child(2) { --index: 2; }
  /* Add as many nth-child rules as you have entries */
  
  /* Fade Effect */
  .timeline-fade-left, .timeline-fade-right {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100px;
    pointer-events: none;
    background: linear-gradient(to left, transparent, white 50%);
    z-index: 10;
  }
  
  .timeline-fade-right {
    right: 0;
    transform: rotateZ(180deg);
  }
  
  /* Scrollbar Styling */
  .timeline-container::-webkit-scrollbar {
    display: none;
  }
  
  .timeline-container {
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
  
  
  @media (max-width: 768px) {  
    .timeline-container {
      padding: 20px; /* Reduce padding on mobile */
    }
  
    .timeline-entry {
      width: 100%; /* Full width timeline entries on mobile */
      min-width: 100; /* Override min-width on mobile */
    }
  
    .timeline-fade-left, .timeline-fade-right {
      position: absolute;
      top: 0;
      bottom: 0;
      width: 10px;
      pointer-events: none;
      background: linear-gradient(to left, transparent, white 50%);
      z-index: 10;
    }
  }
.Card_card__3VyMy {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  background-color: white;
  width: 15rem;
  height: 27rem;
  padding: 1rem;
  border-radius: 15px;
}

.Card_tags__3ROd- {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 1rem;
}

.Card_tag__1txf0 {
  background-color: #f0f0f0;
  border-radius: 25px;
  padding: .5em .75em;
  font-size: .75em;
  color: #333;
  margin: .25em;
}

.Card_card__3VyMy img {
  margin-top: 0rem;
  width: 14rem;
  border-radius: 15px;
}

.Card_card__3VyMy h2 {
  margin: 0;
  margin-top: .5rem;
  color: black;
}

.Card_card__3VyMy p {
  margin: .5rem 0;
  color: black;
}

.Card_readMore__1295y {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 0.125rem;
}

.Card_minutesRead__t622k {
  color: black;
}


.Card_btn__2VzF0 {
  background-color: #0094ff;
  color: #fff;
  border: none;
  outline: none;
  font-size: 1rem;
  border-radius: 20px;
  padding: 5px 0.5rem;
  /* margin: 0 0.5rem ; */
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}


@media (max-width: 768px) {
  .Card_card__3VyMy {
    align-items: flex-start;
    width: 10rem;
    height: 20rem;
    padding: 0.5rem;
    border-radius: 15px;
    align-items: center;
  }
  .Card_card__3VyMy img {
    width: 9rem;
    border-radius: 15px;
    align-items: center;
  }
}
.btn {
    margin: 10px;
    padding: 10px;
    font-size: 16px;
    border-radius: 0.625rem;
  }
  
  .btn-primary {
    background-color: #000;
    color: white;
    border-color: white;
  }
